import React from 'react';
import { Shield, HardDrive, Mail, Check } from 'lucide-react';

const FeatureItem = ({ icon: Icon, text }) => (
  <li className="flex items-center text-gray-300">
    <Check className="mr-2 h-4 w-4 text-red-400 flex-shrink-0" />
    <Icon className="mr-2 h-5 w-5 text-gray-400 flex-shrink-0" />
    <span>{text}</span>
  </li>
);

const PricingCard = ({ title, price, features, mostPopular, checkoutLink }) => (
  <div className={`w-[300px] bg-gray-800 border-gray-700 rounded-lg overflow-hidden ${mostPopular ? 'border-red-500 border-2' : 'border border-gray-700'}`}>
    <div className="p-6">
      <h2 className="text-2xl font-bold text-gray-100 mb-2">{title}</h2>
      {mostPopular && <span className="bg-red-600 text-gray-100 px-2 py-1 rounded-full text-sm font-semibold">Most Popular</span>}
      <p className="text-4xl font-bold my-4 text-gray-100">£{price}<span className="text-sm font-normal text-gray-400">/month</span></p>
      <ul className="space-y-2 mb-6">
        {features.map((feature, index) => (
          <FeatureItem key={index} icon={feature.icon} text={feature.text} />
        ))}
      </ul>
    </div>
    <div className="px-6 pb-6"> {/* Removed bg-gray-900 and adjusted padding */}
      <a 
        href={checkoutLink}
        target="_blank"
        rel="noopener noreferrer"
        className="block w-full bg-red-600 text-gray-100 py-2 px-4 rounded-md hover:bg-red-700 transition-colors text-center font-semibold"
      >
        Choose Plan
      </a>
    </div>
  </div>
);

const ProgressBar = ({ current, goal }) => {
  const progress = (current / goal) * 100;
  return (
    <div className="w-full mb-8">
      <div className="flex justify-between mb-2">
        <span className="text-gray-400">Current: £{current}</span>
        <span className="text-gray-400">Goal: £{goal}</span>
      </div>
      <div className="h-4 bg-gray-700 rounded-full">
        <div 
          className="h-full bg-gradient-to-r from-red-500 to-red-600 rounded-full"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

const PricingTable = () => (
  <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-gray-100 p-8">
    <div className="max-w-6xl mx-auto">
      <h1 className="text-5xl font-bold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-red-400 to-red-600">Choose Your Plan</h1>
      <ProgressBar current={15} goal={21.47} />
      <div className="flex flex-wrap justify-center gap-8">
        <PricingCard
          title="PlexFlex Lite"
          price="2"
          features={[
            { icon: Shield, text: "Free Surfshark Access" }
          ]}
          checkoutLink="https://buy.stripe.com/fZe4i4fM2cQ5gY84go"
        />
        <PricingCard
          title="PlexFlex Standard"
          price="3"
          features={[
            { icon: Shield, text: "Free Surfshark Access" },
            { icon: HardDrive, text: "30GB Encrypted Cloud Storage" }
          ]}
          mostPopular={true}
          checkoutLink="https://buy.stripe.com/dR615SarI17n0ZabIP"
        />
        <PricingCard
          title="PlexFlex Plus"
          price="5"
          features={[
            { icon: Shield, text: "Free Surfshark Access" },
            { icon: HardDrive, text: "50GB Encrypted Cloud Storage" },
            { icon: Mail, text: "Free custom @tinymail.uk email alias" }
          ]}
          checkoutLink="https://buy.stripe.com/14kdSEfM2eYd23eaEK"
        />
      </div>
    </div>
  </div>
);

function App() {
  return (
    <div className="App">
      <PricingTable />
    </div>
  );
}

export default App;